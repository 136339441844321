// Date guardado en firebase se vuelve timestamp
// El input type date devuelve YMD
// El formato en peru DMY

function timeStampToYMD(date) {
  const d = new Date(date.seconds * 1000);
  return `${`000${d.getFullYear()}`.slice(-4)}-${`0${d.getMonth() + 1}`.slice(
    -2,
  )}-${`0${d.getDate()}`.slice(-2)}`;
}

function YMDToDate(date) {
  if (!date) return null;
  const parsedDate = new Date(`${date}T00:00:00`);
  if (Number.isNaN(parsedDate.getTime())) {
    throw new Error(`Fecha inválida: ${date}`);
  }
  return parsedDate;
}

function dateToTimeStamp(date) {
  if (!date) return null;
  return { seconds: date.getTime() / 1000 };
}

function YMDToTimeStamp(date) {
  if (!date) return null;
  return { seconds: YMDToDate(date).getTime() / 1000 };
}

function dateToDMY(date) {
  if (!date) return null;
  return `${`0${date.getDate()}`.slice(-2)}/${`0${date.getMonth() + 1}`.slice(
    -2,
  )}/${`000${date.getFullYear()}`.slice(-4)}`;
}

function YMDToDMY(date) {
  if (!date) return 'No ingresado';
  const d = YMDToDate(date);
  return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(
    -2,
  )}/${`000${d.getFullYear()}`.slice(-4)}`;
}

function YMDToStringDMY(date) {
  if (!date) return 'No ingresado';
  const d = YMDToDate(date);
  const month = d.toLocaleString('sp', { month: 'short' });
  return `${d.getDate()} ${month[0].toUpperCase()}${month.substring(
    1,
  )}. de ${`000${d.getFullYear()}`.slice(-4)}`;
}

function YMDToMMYY(date) {
  if (!date) return 'No ingresado';
  const d = YMDToDate(date);
  const month = d.toLocaleString('sp', { month: 'short' });
  return `${month[0].toUpperCase()}${month.substring(1)}. de ${`000${d.getFullYear()}`.slice(-4)}`;
}

function DMYToYMD(date) {
  if (!date) return null;
  if (date.includes('/')) {
    const chars = date.split('/');
    return `${chars[2]}-${`0${chars[1]}`.slice(-2)}-${`0${chars[0]}`.slice(-2)}`;
  }
  if (date.includes('-')) {
    const chars = date.split('-');
    return `${chars[2]}-${`0${chars[1]}`.slice(-2)}-${`0${chars[0]}`.slice(-2)}`;
  }
  return null;
}

function timeStampToDMY(timeStamp) {
  if (!timeStamp || !timeStamp.seconds) return '';
  const d = new Date(timeStamp.seconds * 1000);
  return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(
    -2,
  )}/${`000${d.getFullYear()}`.slice(-4)}`;
}

function timeStampToDMYOffset(timeStamp) {
  let d = new Date(timeStamp.seconds * 1000);
  const offset = d.getTimezoneOffset();
  d = new Date(d.getTime() - offset * 60 * 1000);
  return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(
    -2,
  )}/${`000${d.getFullYear()}`.slice(-4)}`;
}

function timeStampToYM(timeStamp) {
  const d = new Date(timeStamp.seconds * 1000);
  return `${`000${d.getFullYear()}`.slice(-4)}-${`0${d.getMonth() + 1}`.slice(-2)}`;
}

function timeStampToDate(timeStamp) {
  if (!timeStamp) return null;
  return new Date(timeStamp.seconds * 1000);
}

function timeStampToAge(timeStamp) {
  if (!timeStamp) return null;
  return new Date().getFullYear() - timeStampToDate(timeStamp).getFullYear();
}

function DMYToTimeStamp(date) {
  if (!date) return null;
  if (!date.includes('/')) return null;
  const chars = date.split('/');
  const YMD = `${chars[2]}-${`0${chars[1]}`.slice(-2)}-${`0${chars[0]}`.slice(-2)}`;
  const newDate = YMDToDate(YMD);
  if (!newDate) return null;
  return { seconds: newDate.getTime() / 1000 };
}

function dateToYMD(date) {
  return DMYToYMD(dateToDMY(date));
}

module.exports = {
  YMDToDate,
  YMDToDMY,
  YMDToMMYY,
  YMDToTimeStamp,
  YMDToStringDMY,
  DMYToYMD,
  DMYToTimeStamp,
  timeStampToYMD,
  timeStampToYM,
  timeStampToDMY,
  timeStampToDMYOffset,
  timeStampToDate,
  timeStampToAge,
  dateToTimeStamp,
  dateToDMY,
  dateToYMD,
};
